// Import FirebaseAuth and getFirebase().
import React, {useEffect, useState} from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import Layout from "../components/layout";
import SEO from "../components/seo";

// Configure getFirebase().
const config = {
    apiKey: "AIzaSyAJ6J5WqFgUuBQ_Leja9VY17ty59yQfP0Y",
    authDomain: "my-shopping-list-dev.firebaseapp.com",
    databaseURL: "https://my-shopping-list-dev-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "my-shopping-list-dev",
    storageBucket: "my-shopping-list-dev.appspot.com",
    messagingSenderId: "668566103392",
    appId: "1:668566103392:web:b02408443f9f8c8f6fea05",
    measurementId: "G-H32WZF4LQX"
};

// Configure FirebaseUI.
export const getUiConfig = (signInSuccessHandler = Function.prototype) => {
    console.log('getFirebase().auth?.GoogleAuthProvider', getFirebase(), firebase)
    return ({
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // We will display Google and Facebook as auth providers.
        signInOptions: [
            getFirebase()?.auth.GoogleAuthProvider.PROVIDER_ID,
            getFirebase()?.auth.FacebookAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                signInSuccessHandler(authResult, redirectUrl);
            },
        },
    });
}


let instance;

export function getFirebase() {
    if (typeof window !== 'undefined') {
        if (instance) return firebase;

        instance = firebase.initializeApp(config);
        return instance;
    }

    return null;
}
