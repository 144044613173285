import React, {Fragment, useEffect, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import Modal from "../Modal";
import firebase from "firebase";
import Layout from "../layout";
import SEO from "../seo";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import {getFirebase, getUiConfig, uiConfig} from "../../utils/signin";

const AuthModal = ({isOpen = false, setIsOpen, signInSuccessHandler = Function.prototype}) => {
    const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
    // Listen to the Firebase Auth state and set the local state.
    const firebase = getFirebase();
    useEffect(() => {
        if (!firebase) return;

        const unregisterAuthObserver = getFirebase().auth().onAuthStateChanged(user => {
            setIsSignedIn(!!user);
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);
    if (!firebase) return null;

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <div
                className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                    as="h3"
                    className="text-2xl font-large leading-6 text-gray-900"
                >
                    S'authentifier
                </Dialog.Title>
                <div className="mt-2">
                    <p className="text-sm text-gray-500">
                        En vous vous connectant pour la première fois vous acceptez nos conditions d'utilisation.
                    </p>
                    <div className="p-4">
                        <StyledFirebaseAuth uiConfig={getUiConfig(signInSuccessHandler)}
                                            firebaseAuth={getFirebase().auth()}/>
                    </div>
                </div>

                <div className="mt-4">
                    <button
                        type="button"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClose={() => setIsOpen(false)}
                        onClick={() => setIsOpen(false)}
                    >
                        Fermer
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default AuthModal;
