import { Link } from "gatsby";
import React  from "react";
import logoImg from "../images/logo.png";

function Footer() {

  return (
      <footer className="bg-white border-t-2 border-gray-100">
        <div className="container mx-auto px-8">

          <div className="w-full flex flex-col md:flex-row py-6">

            <div className="mr-4 mb-6 text-gray-800">
              <a className="text-orange-600 no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
                 href="/">
                <img className="h-16 fill-current inline" src={logoImg}/>DeerHome
              </a>
            </div>


            {/*<div className="flex-1">*/}
            {/*    <p className="uppercase text-gray-500 md:mb-6">Links</p>*/}
            {/*    <ul className="list-reset mb-6">*/}
            {/*        <li className="mt-2 inline-block mr-2 md:block md:mr-0">*/}
            {/*            <a href="#"*/}
            {/*               className="no-underline hover:underline text-gray-800 hover:text-orange-500">FAQ</a>*/}
            {/*        </li>*/}
            {/*        <li className="mt-2 inline-block mr-2 md:block md:mr-0">*/}
            {/*            <a href="#"*/}
            {/*               className="no-underline hover:underline text-gray-800 hover:text-orange-500">Help</a>*/}
            {/*        </li>*/}
            {/*        <li className="mt-2 inline-block mr-2 md:block md:mr-0">*/}
            {/*            <a href="#"*/}
            {/*               className="no-underline hover:underline text-gray-800 hover:text-orange-500">Support</a>*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</div>*/}
            <div className="flex-1 flex-col flex justify-end items-end">
              <div>
                <p className="uppercase text-gray-500 md:mb-6">Legal</p>
                <ul className="list-reset mb-6 text-gray-700">
                  <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                    <Link to="/privacy-policy"
                          className="no-underline hover:underline text-gray-800 hover:text-orange-500">Politique
                      de confidentialité</Link>
                  </li>
                  <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                    <Link to="/terms-of-use"
                          className="no-underline hover:underline text-gray-800 hover:text-orange-500">Conditions
                      générales d'utilisation</Link>
                  </li>
                  <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                    <Link to="/terms-of-sales"
                          className="no-underline hover:underline text-gray-800 hover:text-orange-500">Conditions
                      Générales de Vente</Link>
                  </li>
                  <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                    <Link to="/legal-notice"
                          className="no-underline hover:underline text-gray-800 hover:text-orange-500">Mentions
                      légale</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/*<div className="flex-1">*/}
            {/*    <p className="uppercase text-gray-500 md:mb-6">Social</p>*/}
            {/*    <ul className="list-reset mb-6">*/}
            {/*        <li className="mt-2 inline-block mr-2 md:block md:mr-0">*/}
            {/*            <a href="#"*/}
            {/*               className="no-underline hover:underline text-gray-800 hover:text-orange-500">Facebook</a>*/}
            {/*        </li>*/}
            {/*        <li className="mt-2 inline-block mr-2 md:block md:mr-0">*/}
            {/*            <a href="#"*/}
            {/*               className="no-underline hover:underline text-gray-800 hover:text-orange-500">Linkedin</a>*/}
            {/*        </li>*/}
            {/*        <li className="mt-2 inline-block mr-2 md:block md:mr-0">*/}
            {/*            <a href="#"*/}
            {/*               className="no-underline hover:underline text-gray-800 hover:text-orange-500">Twitter</a>*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</div>*/}
            {/*<div className="flex-1">*/}
            {/*    <p className="uppercase text-gray-500 md:mb-6">Company</p>*/}
            {/*    <ul className="list-reset mb-6">*/}
            {/*        <li className="mt-2 inline-block mr-2 md:block md:mr-0">*/}
            {/*            <a href="#"*/}
            {/*               className="no-underline hover:underline text-gray-800 hover:text-orange-500">Official*/}
            {/*                Blog</a>*/}
            {/*        </li>*/}
            {/*        <li className="mt-2 inline-block mr-2 md:block md:mr-0">*/}
            {/*            <a href="#"*/}
            {/*               className="no-underline hover:underline text-gray-800 hover:text-orange-500">About*/}
            {/*                Us</a>*/}
            {/*        </li>*/}
            {/*        <li className="mt-2 inline-block mr-2 md:block md:mr-0">*/}
            {/*            <a href="#"*/}
            {/*               className="no-underline hover:underline text-gray-800 hover:text-orange-500">Contact</a>*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</div>*/}
          </div>
        </div>

      </footer>
  );
}

export default Footer;
